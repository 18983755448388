import { db } from '@/config/firebase';

async function getAuditTrailLogs(param) {
    let parentRef = db.collection(param.collection).doc(param.id);
    let auditTrailLogsRef = parentRef.collection('auditTrailLogs').orderBy('dateCreated', 'desc');
    return auditTrailLogsRef.get().then(querySnapshot => {
        let auditLogs = {};
        querySnapshot.forEach(function (doc) {
            let auditLog = doc.data();
            auditLog.id = doc.id;
            auditLogs[doc.id] = auditLog;
        })
        return auditLogs;
    }).catch(error => {
        return error;
    })
}

export default {
    getAuditTrailLogs
}